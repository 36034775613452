import { fork } from 'redux-saga/effects';
import handleAuthen from './auth';
import handleUser from './user';
import handleHistory from './history';
import handleDevice_type from './device_type';
import handleDevice from './device';
import handleChannel from './channel';
import handleManual from './manual';
import handleReport from './report';
import handleGroup from './group';
import handleNotification from './notification';
import handleConfigvalue from './configvalue';
import handleTemfileSaga from './temfile';
import handlesStorgeTemfileSaga from './storge_tem';

import handleExpenseSaga from './expense';

import handleVersion from './version'
import handleUnitSaga from './unit'
import handlePMaterialSaga from './product_material'
import handlePJMaterialSaga from './project_material'

import handlePMaterialOutSaga from './product_material_out'
import handlePMaterialQuantitySaga from './product_material_quantity';
import handleDashboardSaga from './dasboard';

function* rootSaga() {
    try {
        yield fork(handleDevice_type);
        yield fork(handleAuthen);
        yield fork(handleUser);
        yield fork(handleHistory);
        yield fork(handleVersion);
        yield fork(handleDevice);
        yield fork(handleChannel);
        yield fork(handleManual);
        yield fork(handleReport);
        yield fork(handleGroup);
        yield fork(handleNotification);
        yield fork(handleConfigvalue);
        yield fork(handleTemfileSaga);
        yield fork(handleExpenseSaga);
        yield fork(handlesStorgeTemfileSaga);
        yield fork(handleUnitSaga);
        yield fork(handlePMaterialSaga);
        yield fork(handlePJMaterialSaga);
        yield fork(handlePMaterialOutSaga);
        yield fork(handlePMaterialQuantitySaga);
        yield fork(handleDashboardSaga);
    } catch (error) {
        console.log('error server!!!!!')
    }
}

export default rootSaga; 