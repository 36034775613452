export const   EXPENSE_FETCH_LIST = "EXPENSE_FETCH_LIST";
export const   EXPENSE_FETCH_LIST_SUCCESS = "EXPENSE_FETCH_LIST_SUCCESS";

export const   EXPENSE_FETCH_ITEM_BY_ID = "  EXPENSE_FETCH_ITEM_BY_ID";
export const   EXPENSE_FETCH_ITEM_BY_ID_SUCCESS = "  EXPENSE_FETCH_ITEM_BY_ID_SUCCESS";

export const   EXPENSE_CREATE_UPDATE = " EXPENSE_CREATE_UPDATE";
export const   EXPENSE_CREATE_UPDATE_SUCCESS = " EXPENSE_CREATE_UPDATE_SUCCESS";

export const   EXPENSE_DELETE = "EXPENSE_DELETE";
export const   EXPENSE_DELETE_SUCCESS = "EXPENSE_DELETE_SUCCESS";

export const   EXPENSE_UPDATE = "EXPENSE_UPDATE";
export const   EXPENSE_UPDATE_SUCCESS = "EXPENSE_UPDATE_SUCCESS";


export const   EXPENSE_FETCH_BY_TYPE = " EXPENSE_FETCH_BY_TYPE";
export const   EXPENSE_FETCH_BY_TYPE_SUCCESS = " EXPENSE_FETCH_BY_TYPE_SUCCESS";


export const   EXPENSE_FETCH_autocomplete = " EXPENSE_FETCH_autocomplete";
export const   EXPENSE_FETCH_autocomplete_SUCCESS = " EXPENSE_FETCH_autocomplete_SUCCESS";
