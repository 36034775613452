import { call, put, takeEvery } from "redux-saga/effects";
import { STATUS_CODE } from "../contants";
import * as conatant_type from "../contants/dashboard";

import { handleFetchListViewSuccess } from "../actions/dashboard";

import { fetchListsPost } from "../apis/common";
let url_router_default = "dashboard/";

function* handleFetchListViewSaga({ payload }) {
  const { requets, token } = payload;
  const resp = yield call(
    fetchListsPost,
    url_router_default + "get-list-view-dashboard",
    requets,
    token
  );
  const { data, status } = resp;
  if (status === STATUS_CODE.SUSSESS) {
    yield put(handleFetchListViewSuccess(data));
  }
}

function* handleDashboardSaga() {
  yield takeEvery(
    conatant_type.DASHBOARD_FETCH_LIST_VIEW,
    handleFetchListViewSaga
  );
}
export default handleDashboardSaga;
