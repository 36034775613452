export const   PROJECT_MATERIAL_FETCH_LIST = "PROJECT_MATERIAL_FETCH_LIST";
export const   PROJECT_MATERIAL_FETCH_LIST_SUCCESS = "PROJECT_MATERIAL_FETCH_LIST_SUCCESS";

export const   PROJECT_MATERIAL_FETCH_ITEM_BY_ID = "PROJECT_MATERIAL_FETCH_ITEM_BY_ID";
export const   PROJECT_MATERIAL_FETCH_ITEM_BY_ID_SUCCESS = "PROJECT_MATERIAL_FETCH_ITEM_BY_ID_SUCCESS";

export const   PROJECT_MATERIAL_CREATE_UPDATE = "PROJECT_MATERIAL_CREATE_UPDATE";
export const   PROJECT_MATERIAL_CREATE_UPDATE_SUCCESS = "PROJECT_MATERIAL_CREATE_UPDATE_SUCCESS";

export const   PROJECT_MATERIAL_DELETE = "PROJECT_MATERIAL_DELETE";
export const   PROJECT_MATERIAL_DELETE_SUCCESS = "PROJECT_MATERIAL_DELETE_SUCCESS";

export const   PROJECT_MATERIAL_UPDATE = "PROJECT_MATERIAL_UPDATE";
export const   PROJECT_MATERIAL_UPDATE_SUCCESS = "PROJECT_MATERIAL_UPDATE_SUCCESS";

export const   PROJECT_MATERIAL_FETCH_BY_TYPE = "PROJECT_MATERIAL_FETCH_BY_TYPE";
export const   PROJECT_MATERIAL_FETCH_BY_TYPE_SUCCESS = "PROJECT_MATERIAL_FETCH_BY_TYPE_SUCCESS";

export const   PROJECT_MATERIAL_FETCH_autocomplete = "PROJECT_MATERIAL_FETCH_autocomplete";
export const   PROJECT_MATERIAL_FETCH_autocomplete_SUCCESS = "PROJECT_MATERIAL_FETCH_autocomplete_SUCCESS";

