import * as conatant_types from "../contants/stogreTem";
import { toastError, toastSuccess } from "../helpers/toastHelpers";
const initialState = {
  data_table_item: [],
  data_pagination_item: 0,
  data_create: null,
  initialValues: null,
  text_complete: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {  
    case conatant_types.STORGE_TEM_FETCH_LIST_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        initialValues: null,
        data_table_item: data.data,
        data_pagination_item:
          data.count.length > 0 ? data.count[0].count : null,
      };
    }
    case conatant_types.STORGE_TEM_FETCH_ITEM_BY_ID_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        initialValues: data,
      };
    }
    case conatant_types.STORGE_TEM_CREATE_UPDATE_SUCCESS: {
      const { data } = action.payload;
      const { status } = data;
      if (!status) {
        toastError(data.message);
      } else {
        toastSuccess("Tạo thông tin thành công.");
        return { ...state, data_create: data };
      }
      return { ...state };
    }
    case conatant_types.STORGE_TEM_DELETE_SUCCESS: {
      const { data } = action.payload;
      const { status } = data;
      if (!status) {
        toastError(data.message);
      } else {
        const { resp } = data;
        toastSuccess("Đã xóa thành công.");
        let { data_table_item } = state;
        const index = data_table_item.findIndex((item) => item._id == resp._id);
        data_table_item.splice(index, 1);
        return { ...state, data_table_item: [...data_table_item] };
      }
      return { ...state };
    }
    case conatant_types.STORGE_TEM_UPDATE_SUCCESS: {
      const { data } = action.payload;
      const { status } = data;
      if (!status) {
        toastError(data.message);
      } else {
        toastSuccess("Thay đổi thông tin thành công.");
      }
      return {
        ...state,
      };
    }
   
    default:
      return state;
  }
};

export default reducer;
