import { call, put, takeEvery } from "redux-saga/effects";
import { STATUS_CODE } from "../contants";
import * as conatant_type from "../contants/units";

import {
  handleFetchListSuccess,
  handleCreateUpdateSuccess,
  handleDeleteSuccess,
  handleFetchItemByIdSuccess,
  handleUpdateSuccess,
  handleFetchListActiveSuccess,
} from "../actions/unit";

import {
  fetchLists,
  fetchListsPost,
  handleDeleteApi,
  handleUpdateApi,
} from "../apis/common";
let url_router_default = "unit/";

function* handleFetchListSaga({ payload }) {
  const { requets, token } = payload;
  const resp = yield call(fetchListsPost, url_router_default, requets, token);
  const { data, status } = resp;
  if (status === STATUS_CODE.SUSSESS) {
    yield put(handleFetchListSuccess(data));
  }
}
function* handleFetchListActiveSaga({ payload }) {
  const { requets, token } = payload;
  const resp = yield call(
    fetchListsPost,
    url_router_default + "active/",
    requets,
    token
  );
  const { data, status } = resp;
  if (status === STATUS_CODE.SUSSESS) {
    yield put(handleFetchListActiveSuccess(data));
  }
}

function* handleFetchItemByIdSaga({ payload }) {
  const { id, token } = payload;
  const resp = yield call(fetchLists, url_router_default + id, token);
  const { data, status } = resp;
  if (status === STATUS_CODE.SUSSESS) {
    console.log(data);
    yield put(handleFetchItemByIdSuccess(data));
  }
}

function* handleCreateUpdateSaga({ payload }) {
  const { requets, token } = payload;
  const resp = yield call(
    fetchListsPost,
    url_router_default + "create",
    requets,
    token
  );
  const { data, status } = resp;
  if (status === STATUS_CODE.SUSSESS) {
    yield put(handleCreateUpdateSuccess(data));
  }
}
function* handleDeleteSaga({ payload }) {
  const { id, token } = payload;
  const resp = yield call(handleDeleteApi, url_router_default, id, token);
  const { data, status } = resp;
  if (status === STATUS_CODE.SUSSESS) {
    yield put(handleDeleteSuccess(data));
  }
}

function* handleUpdateItemSaga({ payload }) {
  const { requets, token } = payload;
  const { _id } = requets;
  let url = url_router_default + _id;
  const resp = yield call(handleUpdateApi, url, requets, token);
  const { data, status } = resp;
  if (status === STATUS_CODE.SUSSESS) {
    yield put(handleUpdateSuccess(data));
  }
}

function* handlesStorgeTemfileSaga() {
  yield takeEvery(conatant_type.UNIT_FETCH_LIST, handleFetchListSaga);
  yield takeEvery(conatant_type.UNIT_FETCH_ITEM_BY_ID, handleFetchItemByIdSaga);
  yield takeEvery(conatant_type.UNIT_UPDATE, handleUpdateItemSaga);

  yield takeEvery(conatant_type.UNIT_CREATE_UPDATE, handleCreateUpdateSaga);
  yield takeEvery(conatant_type.UNIT_DELETE, handleDeleteSaga);
  yield takeEvery(
    conatant_type.UNIT_FETCH_LIST_ACTIVE,
    handleFetchListActiveSaga
  );
}
export default handlesStorgeTemfileSaga;
