import * as contant_types from '../contants/device';

export const handleFetchList = (requets = {}, token) => ({
    type: contant_types.DEVICE_FETCH_LIST,
    payload: {
        requets,
        token
    }
})

export const handleFetchListSuccess = data => ({
    type: contant_types.DEVICE_FETCH_LIST_SUCCESS,
    payload: {
        data
    }
})

export const handleFetchItemById = (id, token) => ({
    type: contant_types.DEVICE_FETCH_ITEM_BY_ID,
    payload: {
        id,
        token
    }
})

export const handleFetchItemByIdSuccess = data => ({
    type: contant_types.DEVICE_FETCH_ITEM_BY_ID_SUCCESS,
    payload: {
        data
    }
})

export const handleCreateUpdate = (form_data, token) => ({
    type: contant_types.DEVICE_CREATE_UPDATE,
    payload: {
        form_data,
        token
    }
})

export const handleCreateUpdateSuccess = data => ({
    type: contant_types.DEVICE_CREATE_UPDATE_SUCCESS,
    payload: {
        data
    }
})

export const handleDelete = (id, token) => ({
    type: contant_types.DEVICE_DELETE,
    payload: {
        id,
        token
    }
})

export const handleDeleteSuccess = data => ({
    type: contant_types.DEVICE_DELETE_SUCCESS,
    payload: {
        data
    }
})


export const handleUpdate = (form_data, token) => ({
    type: contant_types.DEVICE_UPDATE,
    payload: {
        form_data,
        token
    }
})

export const handleUpdateSuccess = data => ({
    type: contant_types.DEVICE_UPDATE_SUCCESS,
    payload: {
        data
    }
})

export const handlePayment = (id, token) => ({
    type: contant_types.DEVICE_PAYMENT,
    payload: {
        id,
        token
    }
})

export const handlePaymentSuccess = data => ({
    type: contant_types.DEVICE_PAYMENT_SUCCESS,
    payload: {
        data
    }
})

export const handleFetchListRun = (requets={}, token) => ({
    type: contant_types.DEVICE_FETCH_LIST_RUN,
    payload: {
        requets,
        token
    }
})

export const handleFetchListRunSuccess = data => ({
    type: contant_types.DEVICE_FETCH_LIST_RUN_SUCCESS,
    payload: {
        data
    }
})

export const handleFetchGroupTime = (requets={}, token) => ({
    type: contant_types.DEVICE_FETCH_GROUP_BY_TIMESTAMP,
    payload: {
        requets,
        token
    }
})

export const handleFetchGroupTimeSuccess = data => ({
    type: contant_types.DEVICE_FETCH_GROUP_BY_TIMESTAMP_SUCCESS,
    payload: {
        data
    }
})

export const handleFetchError = (requets={}, token) => ({
    type: contant_types.DEVICE_FETCH_LIST_ERROR,
    payload: {
        requets,
        token
    }
})

export const handleFetchErrorSuccess = data => ({
    type: contant_types.DEVICE_FETCH_LIST_ERROR_SUCCESS,
    payload: {
        data
    }
})

export const handleFetchErrorByChannel = (requets={}, token) => ({
    type: contant_types.DEVICE_FETCH_LIST_ERROR_BY_CHANNEL,
    payload: {
        requets,
        token
    }
})

export const handleFetchErrorByChannelSuccess = data => ({
    type: contant_types.DEVICE_FETCH_LIST_ERROR_BY_CHANNEL_SUCCESS,
    payload: {
        data
    }
})

export const handleFetchDataSetting = (requets={}, token) => ({
    type: contant_types.DEVICE_FETCH_LIST_DATA_SETTING,
    payload: {
        requets,
        token
    }
})

export const handleFetchDataSettingSuccess = data => ({
    type: contant_types.DEVICE_FETCH_LIST_DATA_SETTING_SUCCESS,
    payload: {
        data
    }
})

