import * as conatant_types from '../contants/history';
import { toastError, toastSuccess } from '../helpers/toastHelpers';
const initialState =
{
    data_table_history: [],
    data_pagination_history: 0
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case conatant_types.HISTORY_FETCH_LIST_SUCCESS: {
            const { data } = action.payload;
            return {
                ...state,
                data_table_history: data.data,
                data_pagination_history: data.count && data.count.length > 0 ? data.count[0].count : null
            };
        }
        
        default:
            return state;
    }
};

export default reducer;
