import { call, put, delay, takeEvery } from 'redux-saga/effects';
import { STATUS_CODE } from '../contants';
import * as conatant_type from '../contants/manual';

import {
   handleFetchListSuccess,
   handleCreateUpdateSuccess,
   handleDeleteSuccess,
   handleFetchItemByIdSuccess,
   handleUpdateSuccess,
   handleCountListBySttSuccess,
   handlePublishSuccess,
   handleFetchListTopViewSuccess
} from '../actions/manual';

import { fetchLists, fetchListsPost, handleDeleteApi, handleUpdateApi, handleUpdateUploadApi, handleUploadFileApi } from '../apis/common';
let url_router_default = 'manual/'

function* handleFetchListSaga({ payload }) {
   const { requets, token } = payload;
   const resp = yield call(fetchListsPost, url_router_default, requets, token);
   const { data, status } = resp;
   if (status === STATUS_CODE.SUSSESS) {
      yield put(handleFetchListSuccess(data));
   }
}
function* handleFetchItemByIdSaga({ payload }) {
   const { id, token } = payload;
   const resp = yield call(fetchLists, url_router_default + id, token);
   const { data, status } = resp;
   if (status === STATUS_CODE.SUSSESS) {
      yield put(handleFetchItemByIdSuccess(data));
   }
}

function* handleCreateUpdateSaga({ payload }) {
   const { form_data, token } = payload;
   const resp = yield call(handleUploadFileApi, url_router_default + 'create', form_data, token);

   const { data, status } = resp;
   if (status === STATUS_CODE.SUSSESS) {
      yield put(handleCreateUpdateSuccess(data));
   }
}
function* handleDeleteSaga({ payload }) {
   const { id, token } = payload;
   const resp = yield call(handleDeleteApi, url_router_default, id, token);
   const { data, status } = resp;
   if (status === STATUS_CODE.SUSSESS) {
      yield put(handleDeleteSuccess(data));
   }
}

function* handleUpdateItemSaga({ payload }) {
   const { form_data, token } = payload;
   const { _id } = form_data;
   let url = url_router_default + _id;
   const resp = yield call(handleUpdateUploadApi, url, form_data, token);
   const { data, status } = resp;
   if (status === STATUS_CODE.SUSSESS) {
      yield put(handleUpdateSuccess(data));
   }
}
function* handlePublishSaga({ payload }) {
   const { id, token } = payload;
   let url = url_router_default + 'publish/' + id;
   const resp = yield call(handleUpdateApi, url, {}, token);
   const { data, status } = resp;
   if (status === STATUS_CODE.SUSSESS) {
      yield put(handlePublishSuccess(data));
   }
}
function* handleCountListBySttSaga({ payload }) {
   const { token } = payload;
   const resp = yield call(fetchListsPost, url_router_default + "count-list-by-status/", {}, token);
   const { data, status } = resp;
   if (status === STATUS_CODE.SUSSESS) {
      yield put(handleCountListBySttSuccess(data));
   }
}
function* handleFetchListTopViewSaga({ payload }) {
   const { token } = payload;
   const resp = yield call(fetchListsPost, url_router_default + "count-list-top-view/", {}, token);
   const { data, status } = resp;
   if (status === STATUS_CODE.SUSSESS) {
      yield put(handleFetchListTopViewSuccess(data));
   }
}
function* handleManual() {
   yield takeEvery(conatant_type.MANUAL_FETCH_LIST, handleFetchListSaga);
   yield takeEvery(conatant_type.MANUAL_FETCH_ITEM_BY_ID, handleFetchItemByIdSaga);

   yield takeEvery(conatant_type.MANUAL_CREATE_UPDATE, handleCreateUpdateSaga);
   yield takeEvery(conatant_type.MANUAL_DELETE, handleDeleteSaga);
   yield takeEvery(conatant_type.MANUAL_UPDATE, handleUpdateItemSaga);
   yield takeEvery(conatant_type.MANUAL_PUBLISH, handlePublishSaga);
   yield takeEvery(conatant_type.MANUAL_COUNT_LIST_BY_STT, handleCountListBySttSaga);
   yield takeEvery(conatant_type.MANUAL_FETCH_LIST_TOP_VIEW, handleFetchListTopViewSaga);


}

export default handleManual;