import { call, delay, put, takeEvery, takeLatest } from "redux-saga/effects";
import { STATUS_CODE } from "../contants";
import * as conatant_type from "../contants/product_material_out";

import {
  handleFetchListSuccess,
  handleCreateUpdateSuccess,
  handleDeleteSuccess,
  handleFetchItemByIdSuccess,
  handleUpdateSuccess,
  handleConfirmSuccess,
} from "../actions/product_material_out";

import {
  fetchLists,
  fetchListsPost,
  handleDeleteApi,
  handleUpdateApi,
} from "../apis/common";
let url_router_default = "product-material-out/";

function* handleFetchListSaga({ payload }) {
  const { requets, token } = payload;
  const resp = yield call(fetchListsPost, url_router_default, requets, token);
  const { data, status } = resp;
  if (status === STATUS_CODE.SUSSESS) {
    yield put(handleFetchListSuccess(data));
  }
}
function* handleFetchItemByIdSaga({ payload }) {
  const { id, token } = payload;
  const resp = yield call(fetchLists, url_router_default + id, token);
  const { data, status } = resp;
  if (status === STATUS_CODE.SUSSESS) {
    console.log(data);
    yield put(handleFetchItemByIdSuccess(data));
  }
}

function* handleCreateUpdateSaga({ payload }) {
  const { requets, token } = payload;
  const resp = yield call(
    fetchListsPost,
    url_router_default + "create",
    requets,
    token
  );
  const { data, status } = resp;
  if (status === STATUS_CODE.SUSSESS) {
    yield put(handleCreateUpdateSuccess(data));
    yield delay(2000);
    window.location.reload();
  }
}
function* handleDeleteSaga({ payload }) {
  const { id, token } = payload;
  const resp = yield call(handleDeleteApi, url_router_default, id, token);
  const { data, status } = resp;
  if (status === STATUS_CODE.SUSSESS) {
    yield put(handleDeleteSuccess(data));
  }
}
function* handleConfirmSaga({ payload }) {
  const { requets, token } = payload;
  const resp = yield call(
    fetchListsPost,
    url_router_default + "confirm/",
    requets,
    token
  );
  const { data, status } = resp;
  if (status === STATUS_CODE.SUSSESS) {
    yield put(handleConfirmSuccess(data));
  }
}

function* handleUpdateItemSaga({ payload }) {
  const { requets, token } = payload;
  const { _id } = requets;
  let url = url_router_default + _id;
  const resp = yield call(handleUpdateApi, url, requets, token);
  const { data, status } = resp;
  if (status === STATUS_CODE.SUSSESS) {
    yield put(handleUpdateSuccess(data));
  }
}

function* handlePMaterialOutSaga() {
  yield takeLatest(
    conatant_type.PRODUCT_OUT_MATERIAL_FETCH_LIST,
    handleFetchListSaga
  );
  yield takeLatest(
    conatant_type.PRODUCT_OUT_MATERIAL_FETCH_ITEM_BY_ID,
    handleFetchItemByIdSaga
  );
  yield takeLatest(
    conatant_type.PRODUCT_OUT_MATERIAL_UPDATE,
    handleUpdateItemSaga
  );

  yield takeLatest(
    conatant_type.PRODUCT_OUT_MATERIAL_CREATE_UPDATE,
    handleCreateUpdateSaga
  );
  yield takeLatest(
    conatant_type.PRODUCT_OUT_MATERIAL_CONFIRM,
    handleConfirmSaga
  );
  yield takeLatest(conatant_type.PRODUCT_OUT_MATERIAL_DELETE, handleDeleteSaga);
}
export default handlePMaterialOutSaga;
