import * as conatant_types from '../contants/manual';
import { toastError, toastSuccess } from '../helpers/toastHelpers';
const component_manual = {
    count_by_stt: null,
    list_top_view: null
}
const initialState =
{
    data_table_manual: [],
    data_pagination_manual: 0,
    data_table_manual_run: [],
    data_pagination_manual_run: 0,
    data_create: null,
    initialValues: null,
    ...component_manual
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case conatant_types.MANUAL_FETCH_LIST_SUCCESS: {
            const { data } = action.payload;
            return {
                ...state,
                initialValues: null,
                data_table_manual: data.data,
                data_pagination_manual: data.count.length > 0 ? data.count[0].count : null
            };
        }
        case conatant_types.MANUAL_FETCH_LIST_RUN_SUCCESS: {
            const { data } = action.payload;
            return {
                ...state,
                data_table_manual_run: data.data,
                data_pagination_manual_run: data.count.length > 0 ? data.count[0].count : null
            };
        }
        case conatant_types.MANUAL_FETCH_ITEM_BY_ID_SUCCESS: {
            const { data } = action.payload;
            return {
                ...state,
                initialValues: data
            };
        }
        case conatant_types.MANUAL_CREATE_UPDATE_SUCCESS: {
            const { data } = action.payload;
            const { status } = data;
            if (!status) {
                toastError(data.message)
            } else {
                toastSuccess("Tạo thông tin thành công.")
                return { ...state, data_create: data };
            }
            return { ...state };
        }
        case conatant_types.MANUAL_DELETE_SUCCESS: {
            const { data } = action.payload;
            const { status } = data;
            if (!status) {
                toastError(data.message)
            } else {
                const { resp } = data;
                toastSuccess("Đã xóa thành công.")
                let { data_table_manual } = state;
                const index = data_table_manual.findIndex(item => item._id == resp._id);
                data_table_manual.splice(index, 1);
                return { ...state, data_table_manual: [...data_table_manual] };
            }
            return { ...state };
        }
        case conatant_types.MANUAL_UPDATE_SUCCESS: {
            const { data } = action.payload;
            const { status } = data;
            if (!status) {
                toastError(data.message)
            } else {
                toastSuccess("Thay đổi thông tin thành công.")

            }
            return {
                ...state
            };
        }
        case conatant_types.MANUAL_PUBLISH_SUCCESS: {
            const { data } = action.payload;
            const { status } = data;
            if (!status) {
                toastError(data.message)
            } else {
                toastSuccess("Thay đổi thông tin thành công.")
                let { data_table_manual } = state;
                let index = data_table_manual.findIndex(x => x._id == data.resp._id);
                if (index !== -1) {
                    data_table_manual[index].publish = !data_table_manual[index].publish;
                    return { ...state, data_table_manual: [...data_table_manual] };
                }
                return { ...state };

            }
            return {
                ...state
            };
        }
        case conatant_types.MANUAL_COUNT_LIST_BY_STT_SUCCESS: {
            const { data } = action.payload;
            if (data.status) {
                return { ...state, count_by_stt: data.resp }
            }
            return {
                ...state
            };
        }
        case conatant_types.MANUAL_FETCH_LIST_TOP_VIEW_SUCCESS: {
            const { data } = action.payload;
            if (data.status) {
                return { ...state, list_top_view: data.resp }
            }
            return {
                ...state
            };
        }
        default:
            return state;
    }
};

export default reducer;
