export const   STORGE_TEM_FETCH_LIST = "STORGE_TEM_FETCH_LIST";
export const   STORGE_TEM_FETCH_LIST_SUCCESS = "STORGE_TEM_FETCH_LIST_SUCCESS";

export const   STORGE_TEM_FETCH_ITEM_BY_ID = "STORGE_TEM_FETCH_ITEM_BY_ID";
export const   STORGE_TEM_FETCH_ITEM_BY_ID_SUCCESS = "STORGE_TEM_FETCH_ITEM_BY_ID_SUCCESS";

export const   STORGE_TEM_CREATE_UPDATE = "STORGE_TEM_CREATE_UPDATE";
export const   STORGE_TEM_CREATE_UPDATE_SUCCESS = "STORGE_TEM_CREATE_UPDATE_SUCCESS";

export const   STORGE_TEM_DELETE = "STORGE_TEM_DELETE";
export const   STORGE_TEM_DELETE_SUCCESS = "STORGE_TEM_DELETE_SUCCESS";

export const   STORGE_TEM_UPDATE = "STORGE_TEM_UPDATE";
export const   STORGE_TEM_UPDATE_SUCCESS = "STORGE_TEM_UPDATE_SUCCESS";


export const   STORGE_TEM_FETCH_BY_TYPE = "STORGE_TEM_FETCH_BY_TYPE";
export const   STORGE_TEM_FETCH_BY_TYPE_SUCCESS = "STORGE_TEM_FETCH_BY_TYPE_SUCCESS";

