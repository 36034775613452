import * as conatant_types from '../contants/notification';
const initialState =
{
    data_table: [],
    data_pagination_noti: 0,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
       
        case conatant_types.NOTIF_FETCH_ITEM_BY_DEVICE_SUCCESS: {
            const { data } = action.payload;
            return {
                ...state,
                data_table: data?.data,
                data_pagination_noti: data.count?.length > 0 ? data.count[0].count : null
            };
        }
        
        default:
            return state;
    }
};

export default reducer;
