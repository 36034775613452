import { combineReducers } from "redux";

import { reducer as formReducer } from "redux-form";
import ui from "./ui";
import auth from "./auth";
import user from "./user";
import history from "./history";
import device_type from "./device_type";
import version from "./version";
import device from "./device";
import channel from "./channel";
import manual from "./manual";
import report from "./report";
import group from "./group";
import notification from "./notification";
import configvalue from "./configvalue";
import temfile from "./temfile";
import storgeTem from "./storge_tem";

import expense from "./expense";
import unit from "./unit";
import product_material from "./product_material";

import product_material_out from "./product_material_out";
import product_material_quantity from "./product_material_quantity";
import project_material from "./project_material";
import dashboard from "./dashboard";


const rootReducer = combineReducers({
  ui,
  form: formReducer,
  auth,
  user,
  report,
  history,
  device_type,
  version,
  device,
  channel,
  manual,
  group,
  notification,
  configvalue,
  temfile,
  expense,
  storgeTem,
  unit,
  product_material,
  product_material_out,
  product_material_quantity,
  project_material,
  dashboard
});

export default rootReducer;
