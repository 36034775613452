export const    CONFIG_VALUE_FETCH_LIST = " CONFIG_VALUE_FETCH_LIST";
export const    CONFIG_VALUE_FETCH_LIST_SUCCESS = " CONFIG_VALUE_FETCH_LIST_SUCCESS";

export const    CONFIG_VALUE_FETCH_ITEM_BY_ID = "   CONFIG_VALUE_FETCH_ITEM_BY_ID";
export const    CONFIG_VALUE_FETCH_ITEM_BY_ID_SUCCESS = "   CONFIG_VALUE_FETCH_ITEM_BY_ID_SUCCESS";

export const    CONFIG_VALUE_CREATE_UPDATE = "  CONFIG_VALUE_CREATE_UPDATE";
export const    CONFIG_VALUE_CREATE_UPDATE_SUCCESS = "  CONFIG_VALUE_CREATE_UPDATE_SUCCESS";

export const    CONFIG_VALUE_DELETE = " CONFIG_VALUE_DELETE";
export const    CONFIG_VALUE_DELETE_SUCCESS = " CONFIG_VALUE_DELETE_SUCCESS";

export const    CONFIG_VALUE_UPDATE = " CONFIG_VALUE_UPDATE";
export const    CONFIG_VALUE_UPDATE_SUCCESS = " CONFIG_VALUE_UPDATE_SUCCESS";


export const    CONFIG_VALUE_FETCH_BY_TYPE = "  CONFIG_VALUE_FETCH_BY_TYPE";
export const    CONFIG_VALUE_FETCH_BY_TYPE_SUCCESS = "  CONFIG_VALUE_FETCH_BY_TYPE_SUCCESS";