import * as contant_types from '../contants/report';

export const fetchdDataChannelBySite = (requets = {}, token) => ({
    type: contant_types.REPORT_DATA_CHANNELS_BY_SITE,
    payload: {
        requets,
        token
    }
})

export const fetchdDataChannelBySiteSuccess = (data) => ({
    type: contant_types.REPORT_DATA_CHANNELS_BY_SITE_SUCCESS,
    payload: {
        data
    }
})
export const fetchdMoreDataChannelBySite = (requets = {}, token) => ({
    type: contant_types.REPORT_DATA_MORE_CHANNELS_BY_SITE,
    payload: {
        requets,
        token
    }
})

export const fetchdMoreDataChannelBySiteSuccess = (data) => ({
    type: contant_types.REPORT_DATA_MORE_CHANNELS_BY_SITE_SUCCESS,
    payload: {
        data
    }
})
