import * as contant_types from '../contants/dashboard';

export const handleFetchListView = (requets = {}, token) => ({
    type: contant_types.DASHBOARD_FETCH_LIST_VIEW,
    payload: {
        requets,
        token
    }
})

export const handleFetchListViewSuccess = data => ({
    type: contant_types.DASHBOARD_FETCH_LIST_VIEW_SUCCESS,
    payload: {
        data
    }
})