export const GROUP_FETCH_LIST = "GROUP_FETCH_LIST";
export const GROUP_FETCH_LIST_SUCCESS = "GROUP_FETCH_LIST_SUCCESS";

export const GROUP_FETCH_ITEM_BY_ID = "GROUP_FETCH_ITEM_BY_ID";
export const GROUP_FETCH_ITEM_BY_ID_SUCCESS = "GROUP_FETCH_ITEM_BY_ID_SUCCESS";

export const GROUP_CREATE_UPDATE = "GROUP_CREATE_UPDATE";
export const GROUP_CREATE_UPDATE_SUCCESS = "GROUP_CREATE_UPDATE_SUCCESS";

export const GROUP_DELETE = "GROUP_DELETE";
export const GROUP_DELETE_SUCCESS = "GROUP_DELETE_SUCCESS";

export const GROUP_UPDATE = "GROUP_UPDATE";
export const GROUP_UPDATE_SUCCESS = "GROUP_UPDATE_SUCCESS";


export const GROUP_FETCH_BY_TYPE = "GROUP_FETCH_BY_TYPE";
export const GROUP_FETCH_BY_TYPE_SUCCESS = "GROUP_FETCH_BY_TYPE_SUCCESS";