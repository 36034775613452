import * as conatant_types from '../contants/dashboard';
import { toastError, toastSuccess } from '../helpers/toastHelpers';
const initialState =
{
    lists: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case conatant_types.DASHBOARD_FETCH_LIST_VIEW_SUCCESS: {
            const { data } = action.payload;
            return {
                ...state, lists: data };
        }
        
        default:
            return state;
    }
};

export default reducer;
