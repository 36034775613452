import * as contant_types from '../contants/notification';


export const handleFetchItemByDevice = (request ={}, token) => ({
    type: contant_types.NOTIF_FETCH_ITEM_BY_DEVICE,
    payload: {
        request,
        token
    }
})

export const handleFetchItemByDeviceSuccess = data => ({
    type: contant_types.NOTIF_FETCH_ITEM_BY_DEVICE_SUCCESS,
    payload: {
        data
    }
})
