export const STATUS_CODE = {
    SUSSESS: 200
}
export const USER_ROLES = [{
    value: 'Administator',
    label: 'Administator'
}, {
    value: 'Admin', // Cấp cao nhất hiển thị.
    label: 'Admin'
}, {
    value: 'TPKT', //TP Kỹ Thuật.
    label: 'Tp. KT'
}, {
    value: 'KT', // NV Kỹ Thuật
    label: 'NV KT',
}, {
    value: 'KD', //Kinh Doanh
    label: 'Kinh doanh'
}, {
    value: 'RD',// R&D
    label: 'R&D'
}, {
    value: 'MKT',//Marketting
    label: 'Marketting'
}]
export const colors =[
    'BFEA7C',
    'C40C0C',
    'FDDE55',
    '0000FF',
    'FF9BD2',
    '3AB0FF',
    '39B5E0',
    'FFA500','009DAE',

]