export const MANUAL_FETCH_LIST = "MANUAL_FETCH_LIST";
export const MANUAL_FETCH_LIST_SUCCESS = "MANUAL_FETCH_LIST_SUCCESS";

export const MANUAL_FETCH_ITEM_BY_ID = "MANUAL_FETCH_ITEM_BY_ID";
export const MANUAL_FETCH_ITEM_BY_ID_SUCCESS = "MANUAL_FETCH_ITEM_BY_ID_SUCCESS";

export const MANUAL_CREATE_UPDATE = "MANUAL_CREATE_UPDATE";
export const MANUAL_CREATE_UPDATE_SUCCESS = "MANUAL_CREATE_UPDATE_SUCCESS";

export const MANUAL_DELETE = "MANUAL_DELETE";
export const MANUAL_DELETE_SUCCESS = "MANUAL_DELETE_SUCCESS";

export const MANUAL_UPDATE = "MANUAL_UPDATE";
export const MANUAL_UPDATE_SUCCESS = "MANUAL_UPDATE_SUCCESS";

export const MANUAL_PUBLISH = "MANUAL_PUBLISH";
export const MANUAL_PUBLISH_SUCCESS = "MANUAL_PUBLISH_SUCCESS";


export const MANUAL_FETCH_LIST_RUN = "MANUAL_FETCH_LIST_RUN";
export const MANUAL_FETCH_LIST_RUN_SUCCESS = "MANUAL_FETCH_LIST_RUN_SUCCESS";


export const MANUAL_FETCH_LIST_HISTORY_RUN = "MANUAL_FETCH_LIST_HISTORY_RUN";
export const MANUAL_FETCH_LIST_HISTORY_RUN_SUCCESS = "MANUAL_FETCH_LIST_HISTORY_RUN_SUCCESS";

export const MANUAL_COUNT_LIST_BY_STT = "MANUAL_COUNT_LIST_BY_STT";
export const MANUAL_COUNT_LIST_BY_STT_SUCCESS = "MANUAL_COUNT_LIST_BY_STT_SUCCESS";

export const MANUAL_FETCH_LIST_TOP_VIEW = "MANUAL_FETCH_LIST_TOP_VIEW";
export const MANUAL_FETCH_LIST_TOP_VIEW_SUCCESS = "MANUAL_FETCH_LIST_TOP_VIEW_SUCCESS";