import { call, put, takeEvery } from 'redux-saga/effects';
import { STATUS_CODE } from '../contants';
import * as conatant_type from '../contants/notification';

import {
   handleFetchItemByDeviceSuccess
} from '../actions/notification';

import { fetchListsPost } from '../apis/common';

function* handleFetchItemByDeviceSaga({ payload }) {
   const { request, token } = payload;
   const resp = yield call(fetchListsPost, 'notification/get-list-by-id/', request, token);
   const { data, status } = resp;
   if (status === STATUS_CODE.SUSSESS) {
      yield put(handleFetchItemByDeviceSuccess(data));
   }
}

function* handleAuthen() {
   yield takeEvery(conatant_type.NOTIF_FETCH_ITEM_BY_DEVICE, handleFetchItemByDeviceSaga);
}

export default handleAuthen;