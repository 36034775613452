import * as conatant_types from '../contants/report';
import { toastError, toastSuccess } from '../helpers/toastHelpers';

const initialState =
{
    channels: [],
    datas: [],
    data_more: []
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case conatant_types.REPORT_DATA_CHANNELS_BY_SITE_SUCCESS: {
            const { data } = action.payload;
            if (data.status) {
                return { ...state, channels: data.channels, datas: data.data }
            }
            return {
                ...state
            };

        }
        case conatant_types.REPORT_DATA_MORE_CHANNELS_BY_SITE_SUCCESS: {
            const { data } = action.payload;
            if (data.status) {
                return { ...state, channels: data.channels, data_more: data.data }
            }
            return {
                ...state
            };

        }
        default:
            return state;
    }
};

export default reducer;
