import axiosService from './axiosService';
import config from '../config';
export const fetchLists = (url, token) => {
    return axiosService.get(`${config.REACT_SERVER_CONNECT_URL}/` + url, {
        headers: {
            'x-access-token': token
        }
    });
}

export const fetchListsPost = (url, request, token) => {
    return axiosService.post(`${config.REACT_SERVER_CONNECT_URL}/` + url, request, {
        headers: {
            'x-access-token': token
        }
    });
}


export const handleDeleteApi = (url, id, token) => {
    return axiosService.delete(`${config.REACT_SERVER_CONNECT_URL}/` + url + id, {
        headers: {
            'x-access-token': token
        }
    })
        .catch(err => {
            axiosService.handleError(err);
        });
}

export const handleUpdateApi = (url, requests, token) => {
    return axiosService.patch(`${config.REACT_SERVER_CONNECT_URL}/` + url, requests, {
        headers: {
            'x-access-token': token
        }
    });
}
export const handleUpdateUploadApi = (url, form_data, token) => {
    return axiosService.patch(`${config.REACT_SERVER_CONNECT_URL}/` + url, form_data, {
        headers: {
            'x-access-token': token
        }
    });
}

export const apiPost = (url, request) => {
    return axiosService.post(`${config.REACT_SERVER_CONNECT_URL}/` + url, request);
}

export const handleUploadFileApi = (url, form_data, token) => {
    return axiosService.post(`${config.REACT_SERVER_CONNECT_URL}/${url}`, form_data, {
        headers: {
            'x-access-token': token
        }
    });
}