export const   UNIT_FETCH_LIST = "UNIT_FETCH_LIST";
export const   UNIT_FETCH_LIST_SUCCESS = "UNIT_FETCH_LIST_SUCCESS";

export const   UNIT_FETCH_LIST_ACTIVE = "UNIT_FETCH_LIST_ACTIVE";
export const   UNIT_FETCH_LIST_ACTIVE_SUCCESS = "UNIT_FETCH_LIST_ACTIVE_SUCCESS";

export const   UNIT_FETCH_ITEM_BY_ID = "UNIT_FETCH_ITEM_BY_ID";
export const   UNIT_FETCH_ITEM_BY_ID_SUCCESS = "UNIT_FETCH_ITEM_BY_ID_SUCCESS";

export const   UNIT_CREATE_UPDATE = "UNIT_CREATE_UPDATE";
export const   UNIT_CREATE_UPDATE_SUCCESS = "UNIT_CREATE_UPDATE_SUCCESS";

export const   UNIT_DELETE = "UNIT_DELETE";
export const   UNIT_DELETE_SUCCESS = "UNIT_DELETE_SUCCESS";

export const   UNIT_UPDATE = "UNIT_UPDATE";
export const   UNIT_UPDATE_SUCCESS = "UNIT_UPDATE_SUCCESS";

export const   UNIT_FETCH_BY_TYPE = "UNIT_FETCH_BY_TYPE";
export const   UNIT_FETCH_BY_TYPE_SUCCESS = "UNIT_FETCH_BY_TYPE_SUCCESS";

