import * as conatant_types from '../contants/user';
import { toastError, toastSuccess } from '../helpers/toastHelpers';
const initialState =
{
    data_table_users: [],
    data_pagination_users: 0,
    data_create: null,
    initialValues: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case conatant_types.USER_FETCH_LIST_SUCCESS: {
            const { data } = action.payload;
            return {
                ...state,
                initialValues: null,
                data_table_users: data.data,
                data_pagination_users: data.count.length > 0 ? data.count[0].count : null
            };
        }
        case conatant_types.USER_FETCH_ITEM_BY_ID_SUCCESS: {
            const { data } = action.payload;
            return {
                ...state,
                initialValues: data
            };
        }
        case conatant_types.USER_CREATE_UPDATE_SUCCESS: {
            const { data } = action.payload;
            const { status } = data;
            if (!status) {
                toastError(data.message)
            } else {
                toastSuccess("Tạo tài khoản thành công.")
                return { ...state, data_create: data };
            }
            return { ...state };
        }
        case conatant_types.USER_DELETE_ACCOUNT_SUCCESS: {
            const { data } = action.payload;
            const { status } = data;
            if (!status) {
                toastError(data.message)
            } else {
                const { resp } = data;
                toastSuccess("Đã xóa thành công.")
                let { data_table_users } = state;
                const index = data_table_users.findIndex(item => item._id == resp._id);
                data_table_users.splice(index, 1);
                return { ...state, data_table_users: [...data_table_users] };
            }
            return { ...state };
        }
        case conatant_types.USER_UPDATE_ACCOUNT_SUCCESS: {
            const { data } = action.payload;
            const { status } = data;
            if (!status) {
                toastError(data.message)
            } else {
                toastSuccess("Thay đổi tài khoản thành công.")
            }
            return {
                ...state
            };
        }
        default:
            return state;
    }
};

export default reducer;

// case TypeItem.HANDLE_UPDATE_SUCCESS: {
//     toastSuccess("Update success")
//     const { data } = action.payload;
//     let { lists } = state;
//     const index = lists.findIndex(item => item._id == data._id);
//     if (index != -1) {
//         const newList = [
//             ...lists.slice(0, index),
//             data,
//             ...lists.slice(index + 1),
//         ];
//         return {
//             ...state, lists: newList
//         }
//     }
//     return { ...state };
// }

// case TypeItem.HANDLE_DELETE_SUCCESS: {
//     toastSuccess('remove success');
//     let { lists } = state;
//     const { data } = action.payload;
//     const index = lists.findIndex(item => item._id == data._id);
//     lists.splice(index, 1);
//     return { ...state, lists: [...lists] };
// }