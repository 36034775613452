import { call, put, delay, takeEvery } from 'redux-saga/effects';
import { STATUS_CODE } from '../contants';
import * as conatant_type from '../contants/report';

import {
   fetchdDataChannelBySiteSuccess,
   fetchdMoreDataChannelBySiteSuccess
} from '../actions/report';

import { fetchListsPost } from '../apis/common';
let url_router_default = 'report/'

function* handleFetchListSaga({ payload }) {
   const { requets, token } = payload;
   const resp = yield call(fetchListsPost, url_router_default + "getDataChannelBySite", requets, token);
   const { data, status } = resp;

   if (status === STATUS_CODE.SUSSESS) {
      yield put(fetchdDataChannelBySiteSuccess(data));
   }
}
function* handleFetchMoreListSaga({ payload }) {
   const { requets, token } = payload;
   const resp = yield call(fetchListsPost, url_router_default + "getDataChannelBySite", requets, token);
   const { data, status } = resp;

   if (status === STATUS_CODE.SUSSESS) {
      yield put(fetchdMoreDataChannelBySiteSuccess(data));
   }
}
function* handleDevice() {
   yield takeEvery(conatant_type.REPORT_DATA_CHANNELS_BY_SITE, handleFetchListSaga);
   yield takeEvery(conatant_type.REPORT_DATA_MORE_CHANNELS_BY_SITE, handleFetchMoreListSaga);

}

export default handleDevice;