import * as conatant_types from '../contants/versions';
import { toastError, toastSuccess } from '../helpers/toastHelpers';
const initialState =
{
    data_table_version: [],
    data_pagination_version: 0,
    data_create: null,
    initialValues: null,
    current_version: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case conatant_types.VERSION_FETCH_LIST_SUCCESS: {
            const { data } = action.payload;
            return {
                ...state,
                initialValues: null,
                data_table_version: data.data,
                data_pagination_version: data.count.length > 0 ? data.count[0].count : null
            };
        }
        case conatant_types.VERSION_FETCH_ITEM_BY_ID_SUCCESS: {
            const { data } = action.payload;
            return {
                ...state,
                initialValues: data
            };
        }
        case conatant_types.VERSION_CREATE_UPDATE_SUCCESS: {
            const { data } = action.payload;
            const { status } = data;
            if (!status) {
                toastError(data.message)
            } else {
                toastSuccess("Tạo thông tin thành công.")
                return { ...state, data_create: data };
            }
            return { ...state };
        }
        case conatant_types.VERSION_DELETE_SUCCESS: {
            const { data } = action.payload;
            const { status } = data;
            if (!status) {
                toastError(data.message)
            } else {
                const { resp } = data;
                toastSuccess("Đã xóa thành công.")
                let { data_table_version } = state;
                const index = data_table_version.findIndex(item => item._id == resp._id);
                data_table_version.splice(index, 1);
                return { ...state, data_table_version: [...data_table_version] };
            }
            return { ...state };
        }
        case conatant_types.VERSION_UPDATE_SUCCESS: {
            const { data } = action.payload;
            const { status } = data;
            if (!status) {
                toastError(data.message)
            } else {
                toastSuccess("Thay đổi thông tin thành công.")
            }
            return {
                ...state
            };
        }
        case conatant_types.VERSION_FETCH_BY_TYPE_SUCCESS: {
            const { data } = action.payload;
            const { status } = data;
            if (!status) {
                toastError(data.message)
                return {
                    ...state,
                    current_version: null
                };
            } else {
                return {
                    ...state,
                    current_version: data.data.version
                };
            }
        }
        default:
            return state;
    }
};

export default reducer;
