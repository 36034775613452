import * as contant_types from '../contants/manual';

export const handleFetchList = (requets = {}, token) => ({
    type: contant_types.MANUAL_FETCH_LIST,
    payload: {
        requets,
        token
    }
})

export const handleFetchListSuccess = data => ({
    type: contant_types.MANUAL_FETCH_LIST_SUCCESS,
    payload: {
        data
    }
})

export const handleFetchItemById = (id, token) => ({
    type: contant_types.MANUAL_FETCH_ITEM_BY_ID,
    payload: {
        id,
        token
    }
})

export const handleFetchItemByIdSuccess = data => ({
    type: contant_types.MANUAL_FETCH_ITEM_BY_ID_SUCCESS,
    payload: {
        data
    }
})

export const handleCreateUpdate = (form_data, token) => ({
    type: contant_types.MANUAL_CREATE_UPDATE,
    payload: {
        form_data,
        token
    }
})

export const handleCreateUpdateSuccess = data => ({
    type: contant_types.MANUAL_CREATE_UPDATE_SUCCESS,
    payload: {
        data
    }
})

export const handleDelete = (id, token) => ({
    type: contant_types.MANUAL_DELETE,
    payload: {
        id,
        token
    }
})

export const handleDeleteSuccess = data => ({
    type: contant_types.MANUAL_DELETE_SUCCESS,
    payload: {
        data
    }
})


export const handleUpdate = (form_data, token) => ({
    type: contant_types.MANUAL_UPDATE,
    payload: {
        form_data,
        token
    }
})

export const handleUpdateSuccess = data => ({
    type: contant_types.MANUAL_UPDATE_SUCCESS,
    payload: {
        data
    }
})

export const handlePublish = (id, token) => ({
    type: contant_types.MANUAL_PUBLISH,
    payload: {
        id,
        token
    }
})

export const handlePublishSuccess = data => ({
    type: contant_types.MANUAL_PUBLISH_SUCCESS,
    payload: {
        data
    }
})

export const handleFetchListRun = (requets={}, token) => ({
    type: contant_types.MANUAL_FETCH_LIST_RUN,
    payload: {
        requets,
        token
    }
})

export const handleFetchListRunSuccess = data => ({
    type: contant_types.MANUAL_FETCH_LIST_RUN_SUCCESS,
    payload: {
        data
    }
})

export const handleCountListByStt = ( token) => ({
    type: contant_types.MANUAL_COUNT_LIST_BY_STT,
    payload: {
        token
    }
})

export const handleCountListBySttSuccess = data => ({
    type: contant_types.MANUAL_COUNT_LIST_BY_STT_SUCCESS,
    payload: {
        data
    }
})

export const handleFetchListTopView = ( token) => ({
    type: contant_types.MANUAL_FETCH_LIST_TOP_VIEW,
    payload: {
        token
    }
})

export const handleFetchListTopViewSuccess = data => ({
    type: contant_types.MANUAL_FETCH_LIST_TOP_VIEW_SUCCESS,
    payload: {
        data
    }
})